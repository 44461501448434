'use client'

import { Button } from '@noissue-ui-kit/button'
import { useRouter, usePathname } from 'next/navigation'
import { buttonTypeToVariant, titleSizeToClass } from './contentfulPage.utils'
import { HiArrowRight } from 'react-icons/hi'
import { TContentfulConfigurableCta } from 'types/contentful/configurableCta.types'
import { trackCTAClick } from '@utils/gtm'
import { mapTextColorToClass } from 'app/_components/ContentfulPage/ConfigurableText/ConfigurableText.utils'

export interface IConfigurableCta extends Partial<TContentfulConfigurableCta> {
  className?: string
  onClick?: () => void
}

/**
 * Because we now rush to roll up our new branding button,
 * just copy and change a little to quick match requirements,
 * do not unify the one under [ConfigurableFeatures] and this one
 *
 * TODO: should later to refact the code
 */
export function ConfigurableCta({
  ctaLabel,
  ctaType = 'button',
  ctaColor = 'pitaya',
  ctaLink,
  ctaLinkSize = 'l',
  ctaBtnVariant = 'filled',
  ctaBtnCorner = 'rounded',
  ctaBtnSize = 's',
  className,
  ctaTrackingIdentifier,
  includeCtaLinkArrow,
  onClick,
}: IConfigurableCta) {
  const router = useRouter()
  const pathname = usePathname()
  const externalLinkRegExp = /^((http|https):\/\/)/
  const linkIsExternal = externalLinkRegExp.test(ctaLink || '')

  const sendTrackingData = () => {
    trackCTAClick({
      ctaLinkUrl: ctaLink,
      componentIdentifier: `${ctaTrackingIdentifier}`,
      pageUrl: pathname,
      ctaLabel: ctaLabel,
    })
  }
  const redirectToCtaLink = () => router.push(ctaLink)

  const textSizeToArrowSize = {
    XXS: 'w-[10px] h-[16px]',
    XS: 'w-[12px] h-[19px]',
    L: 'w-[14px] h-[22px]',
    M: 'w-[16px] h-[25px]',
    XL: 'w-[18px] h-[28px]',
    '2XL': 'w-[20px] h-[31px]',
    '3XL': 'w-[22px] h-[34px]',
    '4XL': 'w-[24px] h-[37px]',
  }

  return (
    <>
      {ctaLabel && ctaType === 'button' && (
        <Button
          colour={ctaColor}
          variant={buttonTypeToVariant[ctaBtnVariant]}
          size={ctaBtnSize}
          corners={ctaBtnCorner}
          className={`${className} pointer-events-auto`}
          onClick={() => {
            sendTrackingData()
            if (ctaLink) {
              redirectToCtaLink()
            } else {
              onClick()
            }
          }}
        >
          {ctaLabel}
        </Button>
      )}

      {ctaLabel && ctaType === 'link' && (
        <a
          href={ctaLink}
          target={linkIsExternal ? '_blank' : '_self'}
          className={`
            ${mapTextColorToClass[ctaColor]} ${titleSizeToClass[ctaLinkSize]}
            font-bold flex items-center ${className} group
            pointer-events-auto
            relative
            after:transition-all
            after:duration-300
            after:bottom-[-2px]
            after:absolute
            after:left-0
            after:block
            after:w-[2px]
            after:h-[2px]
            after:bg-transparent
            hover:after:bg-white
            ${
              includeCtaLinkArrow
                ? 'hover:after:w-[calc(100%+8px)]'
                : 'hover:after:w-[calc(100%+2px)]'
            }
          `}
          onClick={sendTrackingData}
        >
          {ctaLabel}&nbsp;
          {includeCtaLinkArrow && (
            <HiArrowRight
              className={`
                ${mapTextColorToClass[ctaColor]} ${textSizeToArrowSize[ctaLinkSize]}
                 transition-all duration-300 group-hover:translate-x-[6px]
              `}
            />
          )}
        </a>
      )}
    </>
  )
}
